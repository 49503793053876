<template>
<div class="">
                
                <section class="pb-24 relative"><nav class="py-10" data-config-=""><div class="container px-4 mx-auto">
                  <div class="flex items-center justify-between relative">
                    <div class="inline-block font-heading text-xl">
convite<span class="text-indigo-300">.info</span>
                    </div>
                    <div class="hidden lg:block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                
                    </div>
                  </div>
                  </div>
                  </nav><div class="container px-4 mx-auto flex flex-wrap">
                  <div class="order-1 md:order-0 flex flex-wrap -mx-4 md:mb-20 items-center justify-center">
                    <div class="w-full md:w-1/3 lg:w-4/12 px-4 mb-8 md:mb-0">
                      <div class="flex -mx-4 items-center">
                        <div class="hidden lg:block w-1/2 px-4">
                          <div class="bg-lime-100 h-48 w-full"></div>
                        </div>
                        <div class="w-full lg:w-1/2 px-4">
                          <div class="hidden md:block bg-indigo-100 w-full h-48 mb-8"></div>
                          <img class="hidden lg:block w-full h-48" src="https://i.ibb.co/HDjrLHF/photo-1517457373958-b7bdd4587205-1696858281997.webp" alt="">
                </div>
                      </div> 
                    </div>
                    <div class="w-full md:w-1/3 lg:w-4/12 px-4 self-stretch mb-8 md:mb-0">
                      <img class="hidden lg:block w-full h-full object-cover" src="https://images.unsplash.com/photo-1472653431158-6364773b2a56?crop=entropy&amp;cs=srgb&amp;fm=jpg&amp;ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHwxNHx8ZXZlbnR8ZW58MHx8fHwxNjk2ODU4MTkxfDA&amp;ixlib=rb-4.0.3&amp;q=85&amp;w=1920" alt="">
                </div>
                    <div class="w-full md:w-1/3 lg:w-4/12 px-4">
                      <div class="flex -mx-4 items-center">
                        <div class="w-full lg:w-1/2 px-4">
                          <img class="hidden lg:block w-full h-48 mb-8" src="https://i.ibb.co/x8bwtvF/photo-1511795409834-ef04bbd61622-1696858250066.webp" alt=""><div class="hidden md:block bg-red-100 w-full h-48"></div>
                        </div>
                        <div class="hidden lg:block w-1/2 px-4">
                          <div class="bg-blue-100 h-48 w-full"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="order-0 md:order-1 max-w-3xl mx-auto mt-9 lg:mt-1 lg:mb-16 md:mb-0">
                    <div class="text-center">
                      <div>
                        <header></header><h1 class="text-5xl lg:text-6xl font-heading mb-6">
                        <span contenteditable="false">Convites digitais</span><br/>
                        </h1>
                        <p class="max-w-md mx-auto mb-8 leading-8" contenteditable="false">Tenha controle das confirmações de presença do seu evento de forma automática.</p>
                        <a class="inline-block w-full md:w-auto px-8 py-3 text-white font-bold bg-black hover:bg-gray-900" href="https://bot.avanso.com.br/conviteinfo" target="_blank">Começar agora</a>
                        
                      </div>
                    </div>
                  </div>
                  </div>
                </section>
                          
                <section class="py-24" style="background-image: url('https://i.ibb.co/XWRFsRQ/features-5-shadow.png'); background-repeat: no-repeat; background-position: center; background-size: cover;">
                  <div class="container px-4 mx-auto">
                    <div class="flex flex-wrap -mx-4">
                      <div class="w-full lg:w-1/2 px-4 mb-10 lg:mb-0">
                        <div class="max-w-md lg:mx-auto">
                          <h2 class="text-4xl lg:text-5xl font-heading">Entenda os benefícios</h2>
                        </div>
                      </div>
                      <div class="w-full lg:w-1/2 px-4">
                        <div class="flex items-center max-w-md mb-10">
                          <p class="leading-8"> Receba no seu e-mail a confirmação de presença</p>
                        </div>
                        <div class="flex items-center max-w-md mb-10">
                          <p class="leading-8"> Controle as informações que serão coletadas na hora da confirmação</p>
                        </div>
                        <div class="flex items-center max-w-md">
                          <p class="leading-8"> Consiga prever a quantidade de participantes</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                          
                <section class="py-24"><div class="container px-4 mx-auto">
                    <div class="max-w-xl mx-auto mb-16 text-center">
                      <h1 class="text-4xl md:text-5xl lg:text-6xl font-heading mb-6" contenteditable="false">Planos</h1>
                      <p class="leading-8" contenteditable="false">Escolha o plano que faz mais sentido para você!</p>
                    </div>
                    <div class="flex flex-wrap -mx-4 items-center">
                      <div class="w-full lg:w-1/3 px-4 mb-12 lg:mb-0">
                        <div class="p-12 max-w-md mx-auto bg-gray-100">
                          <div class="mb-12 items-end justify-between">
                            <h2 class="text-4xl font-heading" contenteditable="false">Convite único</h2>
                            <h5 class="text-xl font-bold text-indigo-300" contenteditable="false">R$90,00</h5>
                          </div>
                          <ul class="mb-10">
                <li class="flex items-center mb-2">
                              <span class="leading-8" contenteditable="false">Convite personalizado</span>
                            </li>
                            <li class="flex items-center mb-2">
                              <span class="leading-8" contenteditable="false">Perguntas de confirmação personalizadas</span>
                            </li>
                
                          </ul>
                <a class="block w-full px-4 py-3 text-center text-white font-bold bg-black hover:bg-gray-900" href="https://bot.avanso.com.br/conviteinfo" target="_blank">Iniciar</a>
                        </div>
                      </div>
                      <div class="w-full lg:w-1/3 px-4 mb-12 lg:mb-0">
                        <div class="p-12 max-w-md mx-auto bg-black">
                          <div class="mb-12 items-end justify-between">
                            <h2 class="text-4xl font-heading text-white" contenteditable="false">Plano Básico</h2>
                            <h5 class="text-xl font-bold text-indigo-300" contenteditable="false">R$190,00/mês</h5>
                          </div>
                          <ul class="mb-10">
                <li class="flex items-center mb-2">
                              <span class="leading-8 text-white" contenteditable="false">Convite personalizado</span>
                            </li>
                            <li class="flex items-center mb-2">
                              <span class="leading-8 text-white" contenteditable="false">Perguntas de confirmação personalizadas</span>
                            </li>
                            <li class="flex items-center mb-2">
                              <span class="leading-8 text-white" contenteditable="false">Até 3 convites mensais</span>
                            </li>
                
                
                
                          </ul>
                <a class="block w-full px-4 py-3 text-center text-white font-bold bg-indigo-300 hover:bg-indigo-400" href="https://bot.avanso.com.br/conviteinfo" target="_blank">Iniciar</a>
                        </div>
                      </div>
                      <div class="w-full lg:w-1/3 px-4">
                        <div class="p-12 max-w-md mx-auto bg-gray-100">
                          <div class="mb-12 items-end justify-between">
                            <h2 class="text-4xl font-heading">Plano Avançado</h2>
                            <h5 class="text-xl font-bold text-indigo-300">R$280,00/mês</h5>
                          </div>
                          <ul class="mb-10">
                            <li class="flex items-center mb-2">
                              <span class="leading-8" contenteditable="false">Convite personalizado</span>
                            </li>
                            <li class="flex items-center mb-2">
                              <span class="leading-8" contenteditable="false">Perguntas de confirmação personalizadas</span>
                            </li>
                            <li class="flex items-center mb-2">
                              <span class="leading-8" contenteditable="false">Até 7 convites mensais</span>
                            </li>
                          </ul>
                <a class="block w-full px-4 py-3 text-center text-white font-bold bg-black hover:bg-gray-900" href="https://bot.avanso.com.br/conviteinfo" target="_blank">Iniciar</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                          
                <section>
                  <div class="container px-4 mx-auto">
                    <div class="max-w-6xl mx-auto mb-16">
                      
                      
                    </div>
                  </div>
                  <div class="bg-gray-100 py-16 text-center">
                    <div class="container px-4 mx-auto">
                      <p class="text-sm text-gray-500">© Convite.info 2022 All right reserved. Powered by AVANSO.com.br</p>
                    </div>
                  </div>
                </section>
              </div>
</template>

<script>
export default {
  name: 'HomeView',
}
</script>
