<template>
  <div id="content" v-show="bd"> 
<section class="relative mt-6 p-4 lg:pt-12">
        <div class="container px-4 mx-auto">
          <div class="max-w-8xl mx-auto py-12 p-6 md:p-12 pt-4 bg-gray-100 text-black">
            <div class="flex flex-wrap -mx-4 items-center">
              <div class="w-full md:w-1/2 lg:w-4/12 px-4 mb-6 md:mb-10 lg:mb-0">
                <h2 class="text-3xl sm:text-4xl font-heading">Confirme sua presença</h2>
              </div>
              <div class="w-full md:w-1/2 lg:w-5/12 px-4 mb-6 md:mb-10 lg:mb-0">
                <p class="leading-8">Clique no botão "Confirmar" e responda as perguntas até o final para ter sua presença confirmada.</p>
              </div>
              <div class="w-full lg:w-3/12 px-4 text-center lg:text-right"><a class="inline-block w-full md:w-auto py-3 px-8 text-center font-bold transition duration-200 text-white bg-green-500 hover:bg-green-600" :href="bd.link">Confirmar</a></div>
            </div>
          </div>
        </div>
      </section>
                
      <section class="mt-8 px-4 lg:pt-24">
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-4 items-center">
            <div class="w-full lg:w-1/2 px-4 mb-16 lg:mb-0">
              <div class="max-w-md">
                <h2 class="text-4xl lg:text-5xl font-heading mb-9">Você está convidado!</h2>
                <p class="leading-8 mb-14">{{bd.descricao}}</p>
                <div>
                  <div class="flex items-center mb-8">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-3">
  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
</svg>

                    <p>{{bd.info}}</p>
                  </div>
                  <div class="flex items-center mb-8">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-3">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
</svg>

                    <p>{{bd.data}}</p>
                  </div>
                  <div class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-3">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
</svg>

                    <p>{{bd.endereco}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-1/2 px-4">
              <div class="relative">
                
                <img class="block w-full relative" :src="bd.imagem" alt="">
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import bd from '@/assets/bd.json'
  

export default {
  name: 'EventView',

  data() {
    return {
      bd
    }
  },

  mounted(){
    var banco = bd.find(it => it.path == this.$route.params.id)
    if(!banco){
      this.$router.push('/404')
    }else{
      this.bd = banco
    }
  },

  methods: {
    
  }
  

}
</script>
