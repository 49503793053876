import { createRouter, createWebHistory } from 'vue-router'
import EventView from '../views/EventView.vue'
import ErrorView from '../views/ErrorView.vue'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/404',
    name: 'error',
    component: ErrorView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:id',
    name: 'evento',
    component: EventView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
