<template>
<section class="relative mt-24">
    <div class="container px-4 mx-auto">
      <div class="max-w-8xl mx-auto py-12 p-6 md:p-12 pt-4 bg-gray-100 text-black">
        <div class="flex flex-wrap -mx-4 items-center">
          <div class="w-full px-4 mb-6 md:mb-10 lg:mb-0">
            <h2 class="text-3xl sm:text-4xl font-heading">Este link de evento não existe.</h2>
          </div>
        
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import bd from '@/assets/bd.json'
  

export default {
  name: 'ErrorView',

  data() {
    return {
      bd
    }
  },

  mounted(){
    var banco = bd.find(it => it.path == this.$route.params.id)
    if(!banco){
      this.$router.push('/404')
    }else{
      this.bd = banco
    }
  },

  methods: {
    
  }
  

}
</script>
